import R from 'ramda'

/**
 *
 * >round_to(100.33333, 2)
 * 100.33
 * >round_to(100.33555,2)
 * 100.34
 */
export const round_to = (nbr, decimal_points) => Math.round(nbr*Math.pow(10,decimal_points)) / Math.pow(10,decimal_points)

/**
 *
 * >productivity_factor(1,2)
 * 4
 * >productivity_factor(0.1,3)
 * 1.3310000000000004
 */
export const productivity_factor = R.curry((p_r, t) => Math.pow((1 + p_r),t))

/**
 * >compute_TW_t({p_r:0.1, t: 0, C_p:1, w_0:10})
 * 0
 * >compute_TW_t({p_r:0.1, t: 4, C_p:1, w_0:1})
 * 0.641
 * >compute_TW_t({p_r:0, t: 1, C_p:0, w_0: 1})
 * 1
 * >compute_TW_t({p_r:0, t: 2, C_p:0, w_0: 1})
 * 2
 * >compute_TW_t({p_r:0, t: 1, C_p:1, w_0: 1})
 * 0
 */
const compute_TW_t =  R.compose(
  ({
     p_r,
     t,
     C_p,
     w_0
   }) => {
    const expression = productivity_factor(p_r)
    const sum = (t0,t1,expression) => {
      return R.reduce(
        (sum,t) => (sum + expression(t)),
        0,
        R.times(R.identity, t1 - t0 + 1)
      )
    }
    const unrounded_result = (w_0*sum(0,t,expression) - C_p*t)
    return round_to(unrounded_result,3)
  },
  R.mapObjIndexed(val => Number(val)),
  R.pick(['p_r','t','C_p','w_0'])
)
/**
 * >compute_xs({x0: 0, interval: 1, nbr_xs: 2})
 * [0,1]
 * >compute_xs({x0, 0, interval: 1, nbr_xs: 3})
 * [0, 1, 2]
 */
const compute_xs = ({x0, interval, nbr_xs}) => {
  return  R.times(index => (x0 + index * interval), nbr_xs)
}

const create_geom_calc = ({w_0,p_r,C_p}) => x => compute_TW_t({
  t: x,
  w_0: w_0,
  p_r: p_r,
  C_p: C_p
})

/**
 * Generates a time series of total hours worked
 * >compute_geometric_progress({w_0:10,p_r:0.01, C_p:1, xs: [0,1,2]})
 * [[0,0], [1,9], [2, 18.1]]
 */
const compute_geometric_progress = ({w_0, p_r, C_p,xs}) => {
    const geometric_calc = create_geom_calc({w_0, p_r, C_p})
    return (
    R.map(
      x => [x, geometric_calc(x)],
      xs
    )
  )
}

/**
 * This is a special case of geometric progress = no investment in learning and
 * no percent increase in productivity
 */
const compute_linear_progress = ({
  w_0,
  xs
}) => compute_geometric_progress({
  w_0,
  p_r: 0,
  C_p: 0,
  xs
})

const getY = R.nth(1)
const mapIndexed = R.addIndex(R.map)

/**
 * output: {name: '1', linear: 30, geometric: 50 }[]
 */
const compute_chart_data = (params) => {
  const { w_0, p_r, C_p } = params
  const cutoff_day = geometric_would_have_surpassed_linear({
    w_0,
    p_r,
    C_p,
  })
  const nbr_of_days = 2*cutoff_day
  let nbr_xs = R.max(100, nbr_of_days);
  const xs = compute_xs({
    x0: 0,
    nbr_xs,
    interval: Math.round(nbr_of_days/nbr_xs)
  })
  const add_name = mapIndexed(([linear, geometric], index) => ({name: `${xs[index]}`, linear, geometric}));
  return R.compose(
    add_name,
    () => R.zip(
      compute_linear_progress({w_0, xs}).map(getY),
      compute_geometric_progress({xs, w_0,C_p,p_r}).map(getY)
    )
  )()
}
/**
 * > geometric_would_have_surpassed_linear({w_0: 10, p_r: 0.002, C_p: 1, nbr_of_days: 365})
 * 96
 */
export const geometric_would_have_surpassed_linear = (params) => {
  const search = ({
                    params,
                    x0 = 0
                  }) => {

    const nbr_of_days = 2*365

    const xs = compute_xs({
      x0,
      nbr_xs: nbr_of_days,
      interval: Math.round(nbr_of_days/nbr_of_days)
    })

    const {
      w_0,
      p_r,
      C_p,
    } = params

    const geom_calc = create_geom_calc({
      C_p,
      p_r,
      w_0
    })
    const linear_calc = create_geom_calc({
      C_p:0,
      p_r:0,
      w_0
    })

    const cutoff_index = xs.findIndex(x => geom_calc(x) > linear_calc(x))
    if(cutoff_index === -1){
      return search({x0: nbr_of_days, params })
    }
    return xs[cutoff_index]
  }
  return search({
    params
  })
}

export default compute_chart_data


