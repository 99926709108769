import React from 'react'
import { Slider } from 'rsuite'
import { InlineMath } from 'react-katex';


import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'
import compute_chart_data, {
  geometric_would_have_surpassed_linear,
  productivity_factor,
  round_to,
} from './compute_chart_data.dt';

const BetterSlider = ({onChange, min, max, defaultValue = (max-min)/2}) => {
  const rescale = val => min + val*(max-min)/100
  const normalize = val => 100*(val-min)/(max-min)
  return <Slider
    defaultValue={normalize(defaultValue)}
    onChange={val => onChange(rescale(val))}
    tooltip={false}
    min={0}
    max={100} />
}
/**
 * test-example: roundTo(2)(0.3333) = 0.33
 */
const roundTo = nbr_decimal_places => nbr => Math.round(nbr*Math.pow(10,nbr_decimal_places))/(Math.pow(10,nbr_decimal_places))
const roundToTwo = roundTo(2)
const InPercent = (percent) => `${roundToTwo(percent*100)}%`

const ExampleChart = () => {
  const original_parameter_values = {
    w_0: 10,
    nbr_of_years: 1,
    p_r: 0.2/100,
    C_p: 1
  }

  const [params, setParams] = React.useState(original_parameter_values)
  const setSingleParam = param => value => setParams({...params,[param]: value})
  const data = compute_chart_data(params)
  const improvedWorkingDay = roundTo(0)(params.w_0*productivity_factor(params.p_r)(365));
  const plr = nbr => (nbr > 1) ? 's' : ''
  const crossOverDays = geometric_would_have_surpassed_linear({
    w_0: params.w_0,
    p_r: params.p_r,
    C_p: params.C_p
  });
  const parameter_model_style = {  paddingTop: '10px' }
  const model_parameters_container = {
    boxShadow: '3px 3px',
    borderRadius: '4px',
    borderWidth: '1px',
    border: 'solid',
    marginTop: '10px',
    padding: '10px',
    backgroundColor: '#ffa500bf'
  };
  return (
    <div>
      <div>
        <li>
          If ...
        </li>
        <li>
          ... my working day is {params.w_0} hour{plr(params.w_0)}
        </li>
        <li>
          ... I spend each day {params.C_p} hour{plr(params.C_p)} improving my productivity by {InPercent(params.p_r)}
        </li>
        <li>
          Then ...
        </li>
        <li>
          ... my daily productivity after {params.nbr_of_years} year{plr(params.nbr_of_years)} would be {improvedWorkingDay} hour{plr(improvedWorkingDay)} (i.e. {round_to(improvedWorkingDay/params.w_0,2)}x compared to when I started)
        </li>
        <li>
          ... my investment in productivity would be paid off after {crossOverDays} day{plr(crossOverDays)}
        </li>
      </div>

      <div style={{marginTop: '10px'}}>
        You can play with the parameters yourself
      </div>

      <div
        style={model_parameters_container}
      >
        <div>
          My working day in hours (i.e. <InlineMath math={'w_0'}/>): {params.w_0}
          <Slider value={params.w_0} onChange={w_0 => {
            if(params.C_p > w_0) {
              setParams({
                ...params,
                C_p: w_0,
                w_0
              })
            } else {
              setSingleParam('w_0')(w_0)
            }
          }} min={0} max={15}/>
        </div>
        <div style={parameter_model_style}>
          productivity increase (i.e. <InlineMath math={'p_r'}/>): {InPercent(params.p_r)}
          <BetterSlider
            value={params.p_r}
            onChange={setSingleParam('p_r')} min={0} max={1/100}/>
        </div>
        <div style={parameter_model_style}>
          Hours spent each day improving my productivity (i.e. <InlineMath math={'C_p'}/>): {params.C_p}
          <Slider
            value={params.C_p}
            onChange={setSingleParam('C_p')} min={0} max={params.w_0}/>
        </div>
      </div>
      <div id={'chart'}>
        <LineChart
          margin={{ top: 50, bottom: 50, left: 50, }}
          width={1000} height={600} data={data} >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis interval={'preserveStart'}
                 label={{ value: "Days", position: 'bottom' }}
                 dataKey="name"
          />
          <YAxis label={{ value: "Total hours worked", angle: -90, position: 'left'}} />
          <Tooltip />
          <Legend
            verticalAlign={'top'}
          />
          <Line
            isAnimationActive={false}
            type="monotone"
            name={'No learning/investment'}
            dataKey="linear"
            dot={false}
            stroke="#8884d8"
          />
          <Line
            isAnimationActive={false}
            dot={false}
            name={'Learning/investment'}
            type="monotone"
            dataKey="geometric"
            stroke="#82ca9d" />
        </LineChart>
        <div style={{textAlign:'center', marginBottom: '10px'}}>
          NOTE: Linear = not investing in your productivity; geometric = investing in your productivity
        </div>
      </div>

    </div>
  )
};
export default ExampleChart